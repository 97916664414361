import React from "react"
import Hero from "./Hero"
import Head from "../utils/Head"

const Services = React.lazy(() => import("./services/Content"))
const CustomSoftware = React.lazy(() => import("./CustomSoftware/Content"))
const StrategyConsultation = React.lazy(() => import("./StrategyConsultation"))
const BusinessStrategy = React.lazy(() =>
  import("./BssinessStrategy/BusinessStrategy")
)
const Estimate = React.lazy(() => import("./Estimate"))
const ServicesSection = React.lazy(() => import("./ServicesSection"))
const Accordian = React.lazy(() => import("./FAQs/Accordian"))
const ContactUs = React.lazy(() => import("../ProductDesign/Footer/ContactUs"))

function ProductStrategy() {
  return (
    <div>
      <Head
        metaTitle="Best Product Strategy Consultants - CodeFulcrum"
        title="Master Your Product Strategy Framework - CodeFulcrum"
        desc="Transform your digital presence with our expert digital product design services. Trust our team's product development strategy to bring your vision to life."
      />

      <Hero id="top" btnText="LET'S DISCUSS HOW" />
      <React.Suspense fallback={<div />}>
        <Services />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <CustomSoftware />
      </React.Suspense>

      <div>
        <React.Suspense fallback={<div />}>
          <StrategyConsultation />
        </React.Suspense>
        <React.Suspense fallback={<div />}>
          <BusinessStrategy />
        </React.Suspense>
      </div>
      <React.Suspense fallback={<div />}>
        <Estimate />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ServicesSection />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <Accordian />
      </React.Suspense>
      <React.Suspense fallback={<div />}>
        <ContactUs type="ProductStrategy" />
      </React.Suspense>
    </div>
  )
}

export default ProductStrategy
