import React from "react"

import Button from "../../shared/Button"
import Heading from "../../shared/Heading"
import "./Hero.scss"
export default function Hero({ btnText, img, children, text, social, id }) {
  return (
    <div className="ps-hero">
      <div className="hero-bg" id={id}>
        <div className="container position-relative">
          <div className="row">
            <div
              className="col-lg-6 hero-text-column"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-anchor-placement="top-bottom"
            >
              <div className="line-wrapper">
                <p className="text">Product Strategy</p>
              </div>
              <Heading variant="58" className="header">
                <h1 className="p-1">The first step to a successful product</h1>
              </Heading>

              <div className="hero-text">
                Let us take care of the big picture. You can trust us to
                formulate and execute a product strategy that's aligned with
                your company's vision and goals.
              </div>

              <div className="button text-capitalize">
                <Button
                  text={btnText}
                  variant="fill"
                  href="/contact"
                  className="text-capitalize"
                />
              </div>
            </div>
            <div className="col-lg-6 img-hero">
              <img
                src="/productStrategy/hero.svg"
                alt="strategy-hero"
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
