import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import ProductStrategy from "../components/ProductStrategy"

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://codefulcrum.com/product-strategy/"
        />
      </Helmet>
      <ProductStrategy />
    </Layout>
  )
}
